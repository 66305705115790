<template>
  <div>
      <div class="sticky-toolbar">
        <b-progress v-if="is_ended==false" :value="current_time" :max="max_time" :precision="2" show-progress class="mb-3"></b-progress>
    </div>
  <div v-for="(question, index) in data_questions" :key="index">
    <!-- contoh soal -->
    <b-alert
        v-if="soal_show == false && question.IsExample == '1' && is_ended == false"  
        show
        variant="light"
        class="alert alert-custom alert-white alert-shadow fade show gutter-b">
       <div class="alert-text">
          <div class="row">
            <p><H1>CONTOH SOAL -- waktu pengerjaan {{ question.Times }} Menit</H1></p>
            <div class="col-auto ml-auto">
              <b-button variant="primary" @click="goToNextQuestion()">Next</b-button>
            </div>
          </div>

            <hr>
            <!-- <div class="d-flex justify-content-center">
            	<b-img :src="question.QuestionImage" fluid alt="Responsive image" class="text-center"> <br><br></b-img>
            </div> -->
               <hr>
             <div class="justify-content-center">
              <b-row>
                <b-col sm="12">
                  <b-form-textarea
                    disabled
                    :id="'text-area2-'+index"
                    placeholder="Auto height textarea"
                    rows="3"
                    max-rows="8"
                     v-model="question.QuestionDescription"
                  ></b-form-textarea>
                </b-col>
              </b-row>
            	<!-- <p v-html="limitAndBreakText(question.QuestionDescription, 50)"></p> -->
            </div>

          <div class="row d-flex justify-content-center" >
            <b-form-radio-group
            stacked
              :id="'radio-group-'+index"
              v-model="question.AnswerContent"
              :options="optionsboxes"
              :name="'radio-group-'+index"
              size="lg" 
              @change="e=>{gantiJawaban(e, question)}"
            ></b-form-radio-group>
          </div>
       </div>
    </b-alert>

    <b-alert
        v-if="soal_show == true && question.IsExample == '0' && is_ended == false"  
        show
        variant="light"
        class="alert alert-custom alert-white alert-shadow fade show gutter-b">
       <div class="alert-text">
          <div class="row">
            <h2>Soal {{ question.QuestionNumber }}</h2>
            <div v-if="index == 1 || index == (data_questions.length - 1)" class="col-auto ml-auto">
              <b-button variant="primary" @click="goToEnd()">Selesai</b-button>
            </div>
          </div>

            <hr>
            <!-- <div class="d-flex justify-content-center">
            	<b-img :src="question.QuestionImage" fluid alt="Responsive image" class="text-center"> <br><br></b-img>
            </div> -->
               <hr>
             <div class="justify-content-center">
              <b-row>
                <b-col sm="12">
                  <b-form-textarea
                    disabled
                    :id="'text-area1-'+index"
                    placeholder="Auto height textarea"
                    rows="3"
                    max-rows="8"
                     v-model="question.QuestionDescription"
                  ></b-form-textarea>
                </b-col>
              </b-row>
            	<!-- <p v-html="limitAndBreakText(question.QuestionDescription, 50)"></p> -->
            </div>

          <div class="row d-flex justify-content-center" >
             <b-form-radio-group
              :id="'radio-group-'+index"
              v-model="question.AnswerContent"
              :options="optionsboxes"
              :name="'radio-group-'+index"
              size="lg" 
              @change="e=>{gantiJawaban(e, question)}"
            ></b-form-radio-group>
          </div>
       </div>
    </b-alert>
    
  </div>

    <b-alert v-if="is_ended == true"  
        show
        variant="light"
        class="alert alert-custom alert-white alert-shadow fade show gutter-b"><H1>Terimakasih sudah mengikuti ujian ini 😊</H1>       
        <a
          class="btn btn-light-primary font-weight-bold"
          @click="onLogout"
          >Sign Out</a
        >
     </b-alert>
  </div>
</template>

<script>
import Services from "@/core/services/psikologi-api/Services";
import ApiService from "@/core/services/api.service";
import Swal from 'sweetalert2'
import localStorage from "@/core/services/store/localStorage";
import { LOGOUT } from "@/core/services/store/auth.module";

export default {
  data() {
    return {
      is_ended: false,
      soal_show: false,
      selected: ['b'],
      Number: 0,
      current_time: 0,
      max_time: 0,
      timerRunning: false,
      remainingTime: 0,
      timerInterval: null,
      data_questions: [],
      data_questions_cache: [],
      soal: 0,
      off: 0,
      maxSelections: 1,
      optionsboxes: [{value: "1", text: "Suka"}, {value: "0", text: "Tidak Suka"}],
      answerList : [],
      historyList: [],
    };
  },
  watch: {
    selected(newVal) {
      const Val = newVal.slice(-1);
      if (newVal.length > this.maxSelections) {
    
        this.$nextTick(() => {
          this.selected = Val; 
        });
      }
    },
    async remainingTime(newVal) {
      // Update value progress bar
      this.current_time = newVal;

      // Reset progress bar jika waktu telah habis
      if (newVal == 0) {
        clearInterval(this.timerInterval);
        // this.current_time = 0;
        this.is_ended = true
        await this.updateParticipantHistory(newVal)
      }
    }
  },
  methods: {
    startTimer() {
    if (!this.timerRunning && this.remainingTime > 0) { // Tambahkan pengecekan timerRunning
      this.timerRunning = true; // Set timerRunning menjadi true
      // Set interval untuk mengurangi remainingTime setiap detik
      this.timerInterval = setInterval(() => {
        this.remainingTime--;
      }, 1000);
    }
  },

    stopTimer() {
        clearInterval(this.timerInterval);
    },
    onLogout() {
      // localStorage.setLocalStorage('Number', 1);
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },

     async goToEnd(){
        this.is_ended = true
        await this.updateParticipantHistory(0)
    },

    limitAndBreakText(text, limit) {
    const words = text.split(" ");
    let result = "";
    let count = 0;

    for (let i = 0; i < words.length; i++) {
      count += words[i].length;

      if (count <= limit) {
        result += words[i] + " ";
      } else {
        result = result.trim() + "<br>";
        count = 0;
        i--; // Re-process the current word in the next iteration
      }
    }

    return result.trim();
  },

  createBox(data){
      var result = []

      var foundObj = data.find((element) => element.KeyList != "")
      var optionsBoxsString = foundObj.KeyList
      var optionsBoxsArr = optionsBoxsString.split(",")
      // optionsBoxsArr = optionsBoxsArr.sort((a, b) => a - b)

      for (var element of optionsBoxsArr) {
        result.push({text: element.toUpperCase(), value: element.toUpperCase()})
      }
      return result
  },

  updateParticipantHistory(times){
      const formData = new FormData();
      formData.append("Times", times);
      formData.append("ParticipantId", this.$route.params.ParticipantID);
      formData.append("Session", this.$route.params.TypeTestID);

      let contentType = `application/form-data`;
      return new Promise((resolve, reject) => {
        Services.PostData(
           ApiService,
          `process/participant_history/updatetimes/`,
          formData,
          contentType,
          response => {
            if (response.status) {
              // Swal.fire({
              //   title: "",
              //   text: "Data saved successfully.",
              //   icon: "success",
              //   heightAuto: true,
              //   timer: 1500
              // });

            } else {
              // Swal.fire({
              //   title: "",
              //   text: response.data.error,
              //   icon: "info",
              //   heightAuto: true,
              //   timer: 1500
              // });
            }
          },
          error => {
            console.error('Error during API call:', error);
          }
        );
      });
  },

  getHistory(){
    return new Promise((resolve, reject) => {
        let contentType = `application/form-data`;
        Services.GetId(
          ApiService,
          `process/participant_history/getbytokenandparticipant`,
          this.$route.params.ParticipantID + "/" + this.$route.params.TypeTestID,
          contentType,
          response => {
            resolve(response.data);
            this.historyList = []
            this.historyList = response.data
          },
          err => {

          }
        );
      });
  },

   getAnswer() {
      return new Promise((resolve, reject) => {
        let contentType = `application/form-data`;

        Services.GetId(
          ApiService,
          `process/participant/answer-minat-bakat/getbytokenandparticipant`,
          this.$route.params.ParticipantID + "/" + this.$route.params.TypeTestID,
          contentType,
          response => {
            resolve(response.data);
            this.answerList = []
            this.answerList = response.data
          },
          err => {

          }
        );
      });
    },

    getMasterQuestions() {
      return new Promise((resolve, reject) => {
        let contentType = `application/form-data`;
        let number = localStorage.getLocalStorage("Number");
        if (number === null) {
            number = 0;
        }

        Services.GetId(
          ApiService,
          `master/questions-minat-bakat/byid`,
          this.$route.params.TypeTestID + "?QuestionNumber=" + number,
          contentType,
          response => {
            resolve(response.data);
            this.data_questions_cache = response.data
            // Concatenate base URL with QuestionImage
            // const baseUrl = 'https://psikologi.user.cloudjkt02.com/';
            // this.data_questions_cache = response.data.map(question => ({
            //   ...question,
            //   QuestionImage: baseUrl + question.QuestionImage,
            //   // OptionsBoxs: this.createBox(question.KeyList)
            // }));

            // this.optionsboxes = this.createBox(this.data_questions_cache)

            this.data_questions_cache = this.data_questions_cache.sort((a, b) => a.QuestionNumber - b.QuestionNumber);

          },
          err => {

          }
        );
      });
    },

    goToNextQuestion() {
      this.soal_show = true
      this.startTimer();
    },

    compareQuestionAndAnswer(){
        for(var i=0; i<this.data_questions_cache.length; i++){
          // this.data_questions[i]['AnswerContent'] = []
          this.data_questions_cache[i]['AnswerContent']  = ""
          this.data_questions_cache[i]['AnswerId']  = ""
          var foundObj = this.answerList.find((element) => element.QuestionID == this.data_questions_cache[i]['QuestionID'])
          if(foundObj != undefined){
            this.data_questions_cache[i]['AnswerContent'] = foundObj.AnswerContent
            this.data_questions_cache[i]['AnswerId'] = foundObj.AnswerId
          }
        }
        return this.data_questions_cache
    },

    async gantiJawaban(newVal, obj){
      await this.saveJawaban(obj)
    },

    saveJawaban(obj) {
      const formData = new FormData();
      formData.append("AnswerId", obj.AnswerId);
      formData.append("AnswerContent", obj.AnswerContent);
      formData.append("QuestionId", obj.QuestionID);
      formData.append("ParticipantId", this.$route.params.ParticipantID);
      formData.append("Token", this.$route.params.TypeTestID);
      formData.append("RemainingTime", this.remainingTime)

      let contentType = `application/form-data`;
      return new Promise((resolve, reject) => {
        Services.PostData(
           ApiService,
          `process/participant/answer-minat-bakat/saveupdateanswer/`,
          formData,
          contentType,
          response => {
            if (response.status) {
              // Swal.fire({
              //   title: "",
              //   text: "Data saved successfully.",
              //   icon: "success",
              //   heightAuto: true,
              //   timer: 1500
              // });

            } else {
              // Swal.fire({
              //   title: "",
              //   text: response.data.error,
              //   icon: "info",
              //   heightAuto: true,
              //   timer: 1500
              // });
            }
          },
          error => {
            console.error('Error during API call:', error);
          }
        );
      });
    },

    findCurrentTime(history_list){
      var result = -1

      for(var i=0; i<history_list.length; i++){
          result = history_list[i].Times
      }
      return result
    },

    findMaxTime(data_questions_list){
      var result = 0
          var foundObj = data_questions_list.find((element) => element.IsExample == 1)
          if(foundObj != undefined)
            result = foundObj['Times'] * 60

          return result
    },

    checkEnded(max, curr){
        this.remainingTime = curr
        if(curr == -1)
          this.remainingTime = max

        var is_ended = false
        if(curr == 0)
          is_ended = true

        return is_ended
    },

    async loadData() {
      Promise.all([await this.getHistory(), await this.getAnswer(), await this.getMasterQuestions()]).then((values) => {
         this.data_questions = this.compareQuestionAndAnswer()
         this.max_time = this.findMaxTime(this.data_questions)
         this.current_time = this.findCurrentTime(this.historyList)
         this.is_ended = this.checkEnded(this.max_time, this.current_time)
      });
    },

    handleBackButton(event) {
      window.history.pushState(null, null, window.location.pathname);
    }
  },
  mounted() {

    this.loadData();
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', this.handleBackButton);
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.handleBackButton);
  },
};
</script>

<style lang="scss" scoped>
.sticky-toolbar{
  width: 100%;
  top: 25%;
  align-items: normal;
}
</style>

